<script setup lang="ts">
    import type { CustomerQuery } from '~/graphql/generated-customer';

    export interface AccountKid {
        name: string;
        birthdate: string;
    }
    interface AccountKidsProps {
        a11yLabel: string;
        kids: CustomerQuery['customer']['kids'];
        id: string | undefined;
        refresh: () => Promise<void>;
    }
    const props = defineProps<AccountKidsProps>();

    const { isoLocale, t } = useLocales();
    const emit = defineEmits<{ (e: 'update:kid', data: { kid: AccountKid; index: number }): void }>();

    const kidsValue = computed<AccountKid[] | undefined>(() => {
        if (!props.kids) return;
        try {
            return JSON.parse(props.kids.value);
        } catch (error) {
            console.error('Error parsing JSON:', { error });
            return [];
        }
    });
    const formatBirthdate = (dateString: string) => {
        if (!dateString) return;
        const date = typeof dateString === 'string' ? Date.parse(dateString) : dateString;
        return new Intl.DateTimeFormat(isoLocale.value).format(date);
    };

    const kidsProperties = (item: AccountKid) => {
        return Object.entries(item).map(([key, value]) => ({ key: key as keyof AccountKid, value: value as AccountKid[keyof AccountKid] }));
    };

    const formatKidsValue = (key: keyof AccountKid, value: AccountKid[keyof AccountKid]) => {
        if (key === 'birthdate') {
            return formatBirthdate(value);
        }
        return value;
    };
    const translateKey = (value?: string): string | undefined => {
        if (!value) return;
        return t(`account.kids.${value}`);
    };

    const handleEdit = (kid: AccountKid, index: number) => {
        emit('update:kid', { kid, index });
    };

    const cardAriaLabel = (child: { name: string }) => {
        return `${props.a11yLabel} - ${child.name}`;
    };
</script>
<template>
    <div
        v-if="kidsValue"
        class="mol-account-kids flex flex-col gap-4 md:grid md:grid-cols-3">
        <template
            v-for="(kid, index) in kidsValue"
            :key="id">
            <atm-edit-card
                @edit="handleEdit(kid, index)"
                :a11y-label="cardAriaLabel(kid)">
                <template v-for="kidProperty in kidsProperties(kid)">
                    <span>{{ translateKey(kidProperty.key) }} {{ formatKidsValue(kidProperty.key, kidProperty.value) }}</span>
                </template>
            </atm-edit-card>
        </template>
    </div>
</template>
