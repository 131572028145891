<script setup lang="ts">
    import type { CustomerAddressFieldsFragment, CustomerQuery } from '~/graphql/generated-customer';

    interface AddressProps {
        a11yLabel: string;
        addresses: CustomerQuery['customer']['addresses'];
        defaultAddress: CustomerQuery['customer']['defaultAddress'];
        refresh: () => Promise<void>;
    }

    const props = defineProps<AddressProps>();
    const { language } = useLocales();
    const countries = useCountries(language.value);

    const emit = defineEmits<{
        (e: 'update:dialog', data: { address: CustomerAddressFieldsFragment; isDefaultAddress: boolean }): void;
    }>();

    const country = ref(countries[0].value);

    const handleEdit = (address?: CustomerAddressFieldsFragment | null, isDefaultAddress: boolean = false) => {
        if (address) emit('update:dialog', { address, isDefaultAddress });
    };

    const zoneCodeOptions = computed(() => zoneCode[country.value as string] || []);
    const zoneCodeLabel = (zoneCodeValue: string | null) => {
        const zone = zoneCodeOptions.value.find((option) => option.value === zoneCodeValue);
        return zone?.label || zoneCodeValue;
    };

    const cardAriaLabel = (address: { address1?: string | null }) => {
        return `${props.a11yLabel} - ${address?.address1}`;
    };
</script>

<template>
    <div
        v-if="addresses || defaultAddress"
        class="mol-account-address flex flex-col gap-4 md:grid md:grid-cols-3">
        <atm-edit-card
            v-if="defaultAddress"
            @edit="handleEdit(defaultAddress, true)"
            :a11y-label="cardAriaLabel(defaultAddress)">
            <address class="not-italic">
                <i>{{ $t('account.address.defaultShort') }}</i>
                <br />
                <span>{{ defaultAddress.firstName }} {{ defaultAddress.lastName }} </span>
                <br />
                <span>{{ defaultAddress.address1 }}</span>
                <template v-if="defaultAddress.address2">
                    <br />
                    <span>{{ defaultAddress.address2 }}</span>
                </template>
                <br />
                <span>{{ defaultAddress.zip }}</span>
                &nbsp;
                <span>{{ defaultAddress.city }}</span>
                <template v-if="defaultAddress.zoneCode">
                    <br />
                    <span>
                        {{ zoneCodeLabel(defaultAddress.zoneCode) }}
                    </span>
                </template>
                <br />
                <span v-if="defaultAddress.country">
                    {{ defaultAddress.country }}
                </span>
                <br />
                <span>{{ defaultAddress.phoneNumber }}</span>
            </address>
        </atm-edit-card>

        <template
            v-if="addresses.edges"
            v-for="(address, index) in addresses.edges">
            <atm-edit-card
                @edit="handleEdit(address.node)"
                :a11y-label="cardAriaLabel(address.node)">
                <address class="not-italic">
                    <span>{{ address.node.firstName }} {{ address.node.lastName }}</span>
                    <br />
                    <span>{{ address.node.address1 }}</span>
                    <template v-if="address.node.address2">
                        <br />
                        <span>{{ address.node.address2 }}</span>
                    </template>
                    <br />
                    <span>{{ address.node.zip }}</span>
                    &nbsp;
                    <span>{{ address.node.city }}</span>
                    <template v-if="address.node.zoneCode">
                        <br />
                        <span>
                            {{ zoneCodeLabel(address.node.zoneCode) }}
                        </span>
                    </template>
                    <br />
                    <span v-if="address.node.country">
                        {{ address.node.country }}
                    </span>
                    <br />
                    <span>{{ address.node.phoneNumber }}</span>
                </address>
            </atm-edit-card>
        </template>
    </div>
</template>
