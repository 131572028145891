import type { FormSelectOption } from '~/components/atm/FormSelectOptions.vue';

export type ZoneCodeType = Record<string, FormSelectOption[]>;

export const zoneCode: ZoneCodeType = {
    ES: [
        { value: 'C', label: 'A Coruña' },
        { value: 'VI', label: 'Álava' },
        { value: 'AB', label: 'Albacete' },
        { value: 'A', label: 'Alicante' },
        { value: 'AL', label: 'Almería' },
        { value: 'O', label: 'Asturias Province' },
        { value: 'AV', label: 'Ávila' },
        { value: 'BA', label: 'Badajoz' },
        { value: 'PM', label: 'Balears Province' },
        { value: 'B', label: 'Barcelona' },
        { value: 'BU', label: 'Burgos' },
        { value: 'CC', label: 'Cáceres' },
        { value: 'CA', label: 'Cádiz' },
        { value: 'S', label: 'Cantabria Province' },
        { value: 'CS', label: 'Castellón' },
        { value: 'CE', label: 'Ceuta' },
        { value: 'CR', label: 'Ciudad Real' },
        { value: 'CO', label: 'Córdoba' },
        { value: 'CU', label: 'Cuenca' },
        { value: 'GI', label: 'Girona' },
        { value: 'GR', label: 'Granada' },
        { value: 'GU', label: 'Guadalajara' },
        { value: 'SS', label: 'Gipuzkoa' },
        { value: 'H', label: 'Huelva' },
        { value: 'HU', label: 'Huesca' },
        { value: 'J', label: 'Jaén' },
        { value: 'LO', label: 'La Rioja Province' },
        { value: 'GC', label: 'Las Palmas' },
        { value: 'LE', label: 'León' },
        { value: 'L', label: 'Lleida' },
        { value: 'LU', label: 'Lugo' },
        { value: 'M', label: 'Madrid Province' },
        { value: 'MA', label: 'Málaga' },
        { value: 'ML', label: 'Melilla' },
        { value: 'MU', label: 'Murcia' },
        { value: 'NA', label: 'Navarra' },
        { value: 'OR', label: 'Ourense' },
        { value: 'P', label: 'Palencia' },
        { value: 'PO', label: 'Pontevedra' },
        { value: 'SA', label: 'Salamanca' },
        { value: 'TF', label: 'Santa Cruz de Tenerife' },
        { value: 'SG', label: 'Segovia' },
        { value: 'SE', label: 'Seville' },
        { value: 'SO', label: 'Soria' },
        { value: 'T', label: 'Tarragona' },
        { value: 'TE', label: 'Teruel' },
        { value: 'TO', label: 'Toledo' },
        { value: 'V', label: 'Valencia' },
        { value: 'VA', label: 'Valladolid' },
        { value: 'BI', label: 'Biscay' },
        { value: 'ZA', label: 'Zamora' },
        { value: 'Z', label: 'Zaragoza' },
    ],
};
