<script setup lang="ts">
    import type { CustomerQuery } from '~/graphql/generated-customer';
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

    interface AccountBikesProps {
        a11yLabel: string;
        bikes: CustomerQuery['customer']['bikes'];
        id: string | undefined;
        refresh: () => Promise<void>;
    }

    export interface AccountBike {
        model: string;
        color: string;
        serialNumber: string;
        purchaseDate: string;
        purchaseDestination: string;
        isUsed: boolean;
    }

    const props = defineProps<AccountBikesProps>();
    const { isoLocale, t, te } = useLocales();

    const emit = defineEmits<{
        (e: 'update:bike', data: { bike: AccountBike; index: number }): void;
    }>();

    const bikes = computed(() => {
        if (!props.bikes) return;
        try {
            return JSON.parse(props.bikes.value) as AccountBike[];
        } catch (error) {
            console.error('Error parsing JSON:', { error });
            return [];
        }
    });

    const formatPurchaseDate = (dateString: string) => {
        const date = typeof dateString === 'string' ? Date.parse(dateString) : dateString; //new Date(dateString);
        return new Intl.DateTimeFormat(isoLocale.value).format(date);
    };

    const bikesProperties = (item: AccountBike) => {
        return Object.entries(item).map(([key, value]) => ({ key: key as keyof AccountBike, value: value as AccountBike[keyof AccountBike] }));
    };

    const formatBikesValue = (key: keyof AccountBike, value: any) => {
        if (key === 'purchaseDate') {
            return formatPurchaseDate(value);
        } else if (key === 'purchaseDestination') {
            return te(`account.bikes.purchaseOption.${value}`) ? t(`account.bikes.purchaseOption.${value}`) : value;
        }
        return value;
    };

    const translateKey = (value: string) => {
        if (!value) return;
        return t(`account.bikes.${value}`);
    };

    const handleEdit = (bike: AccountBike, bikeIndex: number) => {
        emit('update:bike', { bike, index: bikeIndex });
    };

    const cardAriaLabel = (bike: { model: string; color: string }) => {
        return `${props.a11yLabel} - ${bike.model} ${bike.color}`;
    };
</script>
<template>
    <div
        v-if="bikes"
        class="mol-account-bikes flex flex-col gap-4 md:grid md:grid-cols-3">
        <template
            v-for="(bike, index) in bikes"
            :key="id">
            <div class="relative">
                <atm-edit-card
                    @edit="handleEdit(bike, index)"
                    :a11y-label="cardAriaLabel(bike)"
                    class="w-full">
                    <span v-if="bike.model && bike.color">{{ bike.model }}</span>
                    <template v-for="bikeProperty in bikesProperties(bike)">
                        <span v-if="!['model', 'color', 'isUsed'].includes(bikeProperty.key)">
                            {{ translateKey(bikeProperty.key) }}: {{ formatBikesValue(bikeProperty.key, bikeProperty.value) }}
                        </span>
                    </template>
                </atm-edit-card>
                <popover
                    v-if="index === 0"
                    class="relative"
                    v-slot="{ open }">
                    <popover-button
                        type="button"
                        :class="[
                            'text-woom-moon-grey absolute bottom-4 right-4 z-10 h-8 w-8 text-woom-grey-mid transition-all  hover:text-woom-red',
                            { 'animate-bounce text-woom-red': open },
                        ]">
                        <woom-icon-bell class="h-8 fill-current" />
                    </popover-button>
                    <transition
                        enter-from-class="scale-95 opacity-0"
                        enter-active-class="duration-200 ease-out"
                        enter-to-class="opacity-100"
                        leave-from-class="opacity-100"
                        leave-active-class="duration-200 ease-out"
                        leave-to-class="opacity-0 scale-95">
                        <popover-panel
                            class="absolute top-1 z-10 w-full rounded-lg bg-woom-red p-4 text-center text-sm text-white drop-shadow-lg before:absolute before:-top-2 before:right-6 before:border-8 before:border-t-0 before:border-transparent before:border-b-woom-red">
                            {{ $t('account.easterEgg') }}
                        </popover-panel>
                    </transition>
                </popover>
            </div>
        </template>
    </div>
</template>
